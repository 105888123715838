import React from 'react';
import styled from 'styled-components';
import { FONTS } from '../../style/fonts';

const StyledParagraph = styled.p`
  ${FONTS.SMALL};
  line-height: 16px;
`;

export const Paragraph = ({ children }: { children: React.ReactNode }) => <StyledParagraph>{children}</StyledParagraph>;
