import { styled } from 'styled-components';
import { FONTS } from '../../style/fonts';
import React from 'react';
import { COLORS } from '../../style/colors';

const StyledSpan = styled.span<{ color: COLORS; font: FONTS }>`
  color: ${({ color }) => color};
  ${({ font }) => font};
`;

export interface TextProps {
  color?: COLORS;
  children: React.ReactNode;
  font: FONTS;
  id?: string;
  onClick?: () => void;
}

export const Text = ({ children, color = COLORS.BLACK, font, id, onClick }: TextProps) => {
  return (
    <StyledSpan color={color} font={font} id={id} onClick={onClick}>
      {children}
    </StyledSpan>
  );
};

export const SmallText = (props: Omit<TextProps, 'font'>) => <Text {...props} font={FONTS.SMALL} />;

export const MediumText = (props: Omit<TextProps, 'font'>) => <Text {...props} font={FONTS.MEDIUM} />;

export const LargeText = (props: Omit<TextProps, 'font'>) => <Text {...props} font={FONTS.LARGE} />;

export const Heading1 = (props: Omit<TextProps, 'font'>) => <Text {...props} font={FONTS.HEADING_1} />;

export const Heading2 = (props: Omit<TextProps, 'font'>) => <Text {...props} font={FONTS.HEADING_2} />;
