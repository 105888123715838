import { styled } from 'styled-components';
import { COLORS } from '../../style/colors';
import { SmallText } from '../Text/Text';

const StyledChipContainer = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${COLORS.BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface ChipProps {
  value: number;
}

export const Chip = ({ value }: ChipProps) => {
  return (
    <StyledChipContainer>
      <SmallText color={COLORS.WHITE}>{value}</SmallText>
    </StyledChipContainer>
  );
};
