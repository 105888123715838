import { styled } from 'styled-components';
import { LEAGUE } from '../../models/constants';
import { COLORS } from '../../style/colors';
import { LeagueIcon } from '../Icon/Icon';

const SIZE = '2x';

export const StyledLoadingIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface LoadingIconProps {
  league: LEAGUE;
}

export const LoadingIcon = ({ league }: LoadingIconProps) => {
  return (
    <StyledLoadingIconContainer>
      <LeagueIcon league={league} size={SIZE} color={COLORS.BLACK} />
    </StyledLoadingIconContainer>
  );
};
