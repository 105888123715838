import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { MediumText } from '../Text/Text';
import { COLORS } from '../../style/colors';
import { CaretLeftIcon, CaretRightIcon } from '../Icon/Icon';

const StyledStatHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  align-items: center;
  margin: 0 ${CONSTANTS.MARGIN_LARGE};
  height: 24px;
  min-height: 24px;
`;

const StyledLine = styled.div`
  height: 2px;
  border-radius: 1px;
  background-color: ${COLORS.GRAY};
  flex: 1;
`;

export interface StatHeaderProps {
  isPaginated: boolean;
  onNext: () => void;
  title: string;
}

export const StatHeader = ({ isPaginated, onNext, title }: StatHeaderProps) => {
  return (
    <StyledStatHeaderContainer>
      <MediumText>{title}</MediumText>
      <StyledLine />
      {isPaginated && (
        <div onClick={onNext}>
          <CaretRightIcon />
        </div>
      )}
    </StyledStatHeaderContainer>
  );
};
