import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { COLORS } from '../../style/colors';
import { BaseballIcon, BasketballIcon, FootballIcon, HockeyIcon, LeagueIcon, StarIcon } from '../Icon/Icon';
import { MediumText } from '../Text/Text';
import { SelectOption } from '../Select/Select';
import { LEAGUE } from '../../models/constants';

const StyledTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${COLORS.WHITE};
  gap: 2px;
  max-width: ${CONSTANTS.MAX_WIDTH};
`;

const StyledTab = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${CONSTANTS.GAP_SMALL};
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${({ $selected }) => ($selected ? COLORS.WHITE : COLORS.WHITE)};
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  box-shadow: ${({ $selected }) => ($selected ? CONSTANTS.BOX_SHADOW : 'auto')};
  height: 40px;
`;

const StyledTabTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  align-items: center;
`;

export interface TabsProps {
  tabs: SelectOption[];
  onTab: (tab: SelectOption | null) => void;
  value: SelectOption | null;
}

export const Tabs = ({ tabs, onTab, value }: TabsProps) => {
  return (
    <StyledTabsContainer>
      <StyledTab key="all" $selected={!value} onClick={() => onTab(null)}>
        <StyledTabTitle>
          <StarIcon color={!value ? COLORS.BLACK : COLORS.GRAY} />
          <MediumText color={!value ? COLORS.BLACK : COLORS.GRAY}>ALL</MediumText>
        </StyledTabTitle>
      </StyledTab>
      {tabs.map((option: SelectOption) => (
        <StyledTab key={option.value} $selected={option === value} onClick={() => onTab(option)}>
          <StyledTabTitle>
            <LeagueIcon league={option.value} color={option === value ? COLORS.BLACK : COLORS.GRAY} />
            <MediumText color={option === value ? COLORS.BLACK : COLORS.GRAY}>{option.label}</MediumText>
          </StyledTabTitle>
        </StyledTab>
      ))}
    </StyledTabsContainer>
  );
};
