import React from 'react';
import { styled } from 'styled-components';

const StyledBase = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export interface BaseProps {
  children: React.ReactNode;
}

export const Base = ({ children }: BaseProps) => {
  return <StyledBase>{children}</StyledBase>;
};
