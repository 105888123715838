/* eslint-disable no-undef */
import { LEAGUE } from './constants';
import { AppConfig, Boxscore, Player } from './interfaces';
import { get } from 'aws-amplify/api';

// GET /player_stats
export const getPlayerStats = async (player_id: string, league: LEAGUE, limit: number): Promise<Boxscore[] | null> => {
  const restOperation = get({
    apiName: 'api',
    path: '/api/player-stats',
    options: {
      queryParams: {
        player_id,
        league,
        limit: `${limit}`,
      },
    },
  });

  const { body } = await restOperation.response;
  const response = await body.json();

  return response as unknown as Boxscore[];
};

const getPlayersTransformer = (data: Omit<Player, 'search_name' | 'first' | 'last'>[]): Player[] =>
  data.map(elem => {
    const search_name = elem['name'].toLowerCase();
    const [first, last] = search_name.split(' ', 1);
    return {
      ...elem,
      search_name,
      first,
      last,
    } as Player;
  });

// GET /players
export const getPlayers = async (league: LEAGUE): Promise<Player[] | null> => {
  const restOperation = get({
    apiName: 'api',
    path: '/api/players',
    options: {
      queryParams: {
        league,
      },
    },
  });

  const { body } = await restOperation.response;
  const response = await body.json();

  return getPlayersTransformer(response as any);
};

// GET /app_config
export const getAppConfig = async (): Promise<AppConfig | null> => {
  const restOperation = get({
    apiName: 'api',
    path: '/api/config',
  });

  const { body } = await restOperation.response;
  const response = await body.json();

  return response as unknown as AppConfig;
};
