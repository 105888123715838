import React, { SyntheticEvent, useContext } from 'react';
import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { COLORS } from '../../style/colors';
import { ModalContext } from '../../contexts/ModalContext';
import { CloseIcon } from '../Icon/Icon';

const StyledModalContainer = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  padding: 20px;
  z-index: 4;
  box-shadow: ${CONSTANTS.BOX_SHADOW};
  width: 66vw;
`;

const StyledIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export interface ModalProps {
  children: React.ReactNode;
  id: string;
}

export const Modal = ({ children, id }: ModalProps) => {
  const { hideModal } = useContext(ModalContext);

  const onClick = (event: SyntheticEvent) => event.stopPropagation();

  return (
    <StyledModalContainer id={id} onClick={onClick} role="dialog">
      <StyledIconContainer>
        <div onClick={hideModal}>
          <CloseIcon onClick={hideModal} />
        </div>
      </StyledIconContainer>
      {children}
    </StyledModalContainer>
  );
};
