import styled from 'styled-components';
import { Modal } from '../Modal/Modal';
import { Heading2, SmallText } from '../Text/Text';
import { CONSTANTS } from '../../style/constants';
import { COLORS } from '../../style/colors';
import { Paragraph } from '../Paragraph/Paragraph';
import { TagIcon } from '../Icon/Icon';

const StyledInfoModalContainer = styled.div`
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  background-color: ${COLORS.WHITE};
`;

const StyledVersionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_SMALL};
  align-items: center;
  justify-content: center;
`;

export const InfoModal = () => {
  return (
    <Modal id="info-modal">
      <StyledInfoModalContainer>
        <Heading2>Last Games</Heading2>
        <Paragraph>
          Last Games is an application designed to give casual bettors quick acess to recent player performances to help them with player props.
          Simply search a player, the statistics to fetch, and the player&apos;s number of &quot;last games&quot;.
        </Paragraph>
        <Paragraph>
          After player data appears, users can create easy to understand visualizations to help them make decisions quickly. Being informed of recent
          player performances is essential to increasing the odds of winning a player prop bet.
        </Paragraph>
        <Paragraph>Currently, the following leagues are supported: NFL, NBA, NHL, MLB.</Paragraph>
        <StyledVersionContainer>
          <TagIcon />
          <SmallText>v 1.0.0</SmallText>
        </StyledVersionContainer>
      </StyledInfoModalContainer>
    </Modal>
  );
};
