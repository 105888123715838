import { SelectOption } from '../components/Select/Select';

export interface NBABoxscore {
  three_pointers_made: number;
  assists: number;
  steals: number;
  blocks: number;
  rebounds_total: number;
  points: number;
  opponent: string;
  gameday: string;
  is_away: boolean;
}

export type NBAStat = keyof Omit<NBABoxscore, 'opponent' | 'gameday' | 'is_away'>;

export const NBA_STAT_OPTIONS: SelectOption[] = [
  {
    label: 'Assists',
    value: 'assists',
  },
  {
    label: 'Blocks',
    value: 'blocks',
  },
  {
    label: 'Points',
    value: 'points',
  },
  {
    label: 'Rebounds',
    value: 'rebounds_total',
  },
  {
    label: 'Steals',
    value: 'steals',
  },
  {
    label: 'Three Pointers',
    value: 'three_pointers_made',
  },
];
