import { styled } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { ModalProvider } from './contexts/ModalContext';
import { AppProvider } from './contexts/AppContext';
import { ErrorBoundary } from 'react-error-boundary';
import { Error } from './components/Error/Error';

const AppContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
`;

const App = () => {
  return (
    <AppContainer>
      <AppProvider>
        <ModalProvider>
          <Header />
          <ErrorBoundary fallback={<Error />}>
            <Outlet />
          </ErrorBoundary>
        </ModalProvider>
      </AppProvider>
    </AppContainer>
  );
};

export default App;
