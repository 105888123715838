import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { CaretDownIcon, CaretUpIcon } from '../Icon/Icon';
import { MediumText } from '../Text/Text';
import { COLORS } from '../../style/colors';
import { useState } from 'react';
import { Chip } from '../Chip/Chip';

const StyledSelectWrapper = styled.div`
  position: relative;
  height: 40px;
  flex: 1;
`;

const StyledSelectContainer = styled.div`
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  box-shadow: ${CONSTANTS.BOX_SHADOW};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${COLORS.WHITE};
  overflow: hidden;
`;

const StyledSelectButton = styled.div`
  height: 40px;
  padding: 0 ${CONSTANTS.MARGIN_LARGE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${CONSTANTS.MAX_SCROLL_HEIGHT};
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const StyledOptionItem = styled.div<{ $selected: boolean }>`
  padding: ${CONSTANTS.PADDING_LARGE};
  scroll-snap-align: start;
`;

export interface SelectOption {
  label: string;
  value: any;
}

export interface SelectProps {
  multiselect?: boolean;
  onSelect: (selected: SelectOption[]) => void;
  options: SelectOption[];
  title: string;
  value: SelectOption[];
}

export const Select = ({ multiselect = false, onSelect, options, title, value }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSelectButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClick = (option: SelectOption[]) => {
    if (multiselect) {
      const [selected] = option;
      if (value.includes(selected)) {
        onSelect(value.filter(x => x !== selected));
      } else {
        onSelect([...value, selected]);
      }
    } else {
      onSelect(option);
      setIsOpen(false);
    }
  };

  return (
    <StyledSelectWrapper>
      <StyledSelectContainer>
        <StyledSelectButton onClick={onSelectButtonClick}>
          {multiselect && <MediumText color={COLORS.BLACK}>{title}</MediumText>}
          {!multiselect && <MediumText color={COLORS.BLACK}>{value.length ? value[0].label : title}</MediumText>}
          {multiselect && value.length > 0 && <Chip value={value.length} />}
          {!isOpen && <CaretDownIcon />}
          {isOpen && <CaretUpIcon />}
        </StyledSelectButton>
        {isOpen && (
          <StyledOptionsContainer>
            {options.map(option => {
              return (
                <StyledOptionItem key={option.label} onClick={() => onOptionClick([option])} $selected={value.includes(option)}>
                  <MediumText color={value.includes(option) ? COLORS.BLACK : COLORS.GRAY}>{option.label}</MediumText>
                </StyledOptionItem>
              );
            })}
          </StyledOptionsContainer>
        )}
      </StyledSelectContainer>
    </StyledSelectWrapper>
  );
};
