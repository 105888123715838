export enum FONTS {
  SMALL = `
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  `,
  MEDIUM = `
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  `,
  LARGE = `
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  `,
  XLARGE = `
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  `,
  HEADING_1 = `
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  font-weight: 800;
  font-style: italic;
  `,
  HEADING_2 = `
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  `,
}
