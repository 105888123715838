import styled from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { LargeText, SmallText } from '../Text/Text';
import { Button } from '../Button/Button';
import { ErrorIcon } from '../Icon/Icon';
import { COLORS } from '../../style/colors';

const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 0 0;
`;

const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border: solid 2px ${COLORS.RED};
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  width: fit-content;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CONSTANTS.GAP_LARGE};
  text-align: center;
`;

export const Error = () => {
  return (
    <StyledErrorWrapper>
      <StyledErrorContainer>
        <ErrorIcon />
        <StyledTextContainer>
          <LargeText>Oops, something went wrong...</LargeText>
          <SmallText>Please refresh the page to try again.</SmallText>
        </StyledTextContainer>
        <Button title="Refresh" onClick={() => window.location.reload()}></Button>
      </StyledErrorContainer>
    </StyledErrorWrapper>
  );
};
