import { Modal } from '../Modal/Modal';
import { Heading2 } from '../Text/Text';

export const SettingsModal = () => {
  return (
    <Modal id="settings-modal">
      <Heading2>Settings</Heading2>
    </Modal>
  );
};
