import { OverUnderInputState } from '../components/OverUnderInput/OverUnderInput';
import { SelectOption } from '../components/Select/Select';
import { Boxscore, LeagueStat, OVER_UNDER_CONTEXT, Player } from './interfaces';

export const getAverageFromBoxscore = (boxscores: Boxscore[], stat: LeagueStat): number =>
  boxscores.reduce((acc: number, boxscore: Boxscore) => acc + boxscore[stat], 0) / boxscores.length;

export const getOverUnderCountFromBoxscore = (boxscores: Boxscore[], stat: LeagueStat, { context, value }: OverUnderInputState): number => {
  return boxscores.reduce((acc: number, boxscore: Boxscore) => {
    const statValue = boxscore[stat];
    switch (context) {
      case OVER_UNDER_CONTEXT.OVER:
        return statValue >= value! ? acc + 1 : acc;
      case OVER_UNDER_CONTEXT.UNDER:
        return statValue < value! ? acc + 1 : acc;
      default:
        return acc;
    }
  }, 0);
};

export const concatStats = (stats: SelectOption[]): string => {
  const labels = stats.map(x => x.label);
  return labels.join(', ');
};

export const generateMenuSnippet = (player: Player, stats: SelectOption[], lastGames: number): string =>
  `${player.name} ${concatStats(stats)} Last ${lastGames} Games`;

export const formatGameday = (gameday: string): string => {
  const [month, day] = gameday.split('-').map(x => parseInt(x));
  return `${month}/${day}`;
};
