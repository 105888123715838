import React, { createContext, useState } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../style/colors';
import { InfoModal } from '../components/InfoModal/InfoModal';
import { SettingsModal } from '../components/SettingsModal/SettingsModal';

export enum MODAL {
  INFO,
  SETTINGS,
}

const StyledModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${COLORS.BLACK};
  opacity: 0.3;
  z-index: 3;
`;

interface ModalContextProps {
  modal: MODAL | null;
  setModal: (modal: MODAL) => void;
  hideModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
  modal: null,
  setModal: (modal: MODAL) => {},
  hideModal: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState<MODAL | null>(null);

  const hideModal = () => setModal(null);

  const value = {
    modal,
    setModal,
    hideModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {modal !== null && (
        <StyledModalContainer onClick={hideModal}>
          <StyledModalOverlay />
          {modal === MODAL.INFO && <InfoModal />}
          {modal === MODAL.SETTINGS && <SettingsModal />}
        </StyledModalContainer>
      )}
      {children}
    </ModalContext.Provider>
  );
};
