export enum CONSTANTS {
  BORDER_RADIUS = '3px',
  BOX_SHADOW = 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  GAP_SMALL = '5px',
  GAP_LARGE = '10px',
  MARGIN_LARGE = '10px',
  MARGIN_SMALL = '5px',
  PADDING_LARGE = '10px',
  PADDING_SMALL = '5px',
  MAX_WIDTH = '600px',
  MAX_SCROLL_HEIGHT = '450px',
}

export enum DESKTOP {
  SMALL = '@media screen and (min-width: 601px)',
  MEDIUM = '@media screen and (min-width: 1191px)',
  LARGE = '@media screen and (min-width: 1781px)',
}
