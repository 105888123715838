import { styled } from 'styled-components';
import { CloseIcon, SearchIcon } from '../Icon/Icon';
import { CONSTANTS } from '../../style/constants';
import { FONTS } from '../../style/fonts';
import { COLORS } from '../../style/colors';
import { MediumText } from '../Text/Text';
import { useEffect, useRef, useState } from 'react';
import { Player } from '../../models/interfaces';

const StyledSearchWrapper = styled.div`
  position: relative;
  height: 40px;
`;

const StyledSearchContainer = styled.div`
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  box-shadow: ${CONSTANTS.BOX_SHADOW};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${COLORS.WHITE};
`;

const StyledSearchBarContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${CONSTANTS.MARGIN_LARGE};
`;

const StyledInput = styled.input`
  background-color: ${COLORS.WHITE};
  flex: 1;
  border: none;
  margin: 0 ${CONSTANTS.MARGIN_LARGE};
  height: 20px;
  ${FONTS.MEDIUM}

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${COLORS.BLACK};
  }
`;

const StyledAutocompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${CONSTANTS.MAX_SCROLL_HEIGHT};
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const StyledAutocompleteItem = styled.div`
  padding: ${CONSTANTS.PADDING_LARGE};
  scroll-snap-align: start;
`;

export interface SearchProps {
  players: Player[];
  onSearch: (value: Player | null) => void;
  placeholder?: string;
  value: Player | null;
}

export const Search = ({ onSearch, placeholder, players, value }: SearchProps) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState(value?.search_name ?? '');
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [suggestions, setSuggestions] = useState<Player[]>([]);

  const filterPredicate = (player: Player): boolean => {
    return player.search_name.startsWith(searchValue) || player.search_name.includes(searchValue);
  };

  const sortPredicate = (a: Player, b: Player): number => (a.name > b.name ? 1 : -1);

  const onAutocompleteClick = (player: Player) => {
    if (searchRef.current) {
      searchRef.current.value = player.name;
      setSearchValue(player.search_name);
      setShowAutocomplete(false);
      onSearch(player);
    }
  };

  const onClearClick = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
      setSearchValue('');
      onSearch(null);
    }
  };

  useEffect(() => {
    if (searchValue) {
      const newSuggestions = players.filter(filterPredicate).sort(sortPredicate);
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!value && searchRef.current) {
      searchRef.current.value = '';
      setSearchValue('');
    }
  }, [value]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = '';
      setSearchValue('');
      onSearch(null);
    }
  }, [players]);

  return (
    <StyledSearchWrapper>
      <StyledSearchContainer>
        <StyledSearchBarContainer>
          <SearchIcon id="" />
          <StyledInput
            spellCheck="false"
            type="search"
            placeholder={placeholder}
            ref={searchRef}
            onChange={event => setSearchValue(event.target.value.toLowerCase())}
            onFocus={() => setShowAutocomplete(true)}
          />
          {searchValue !== '' && (
            <div onClick={onClearClick}>
              <CloseIcon id="" />
            </div>
          )}
        </StyledSearchBarContainer>
        {showAutocomplete && (
          <StyledAutocompleteContainer>
            {suggestions.map(suggestion => {
              return (
                <StyledAutocompleteItem key={suggestion.player_id} onClick={() => onAutocompleteClick(suggestion)}>
                  <MediumText>
                    {suggestion.name} - {suggestion.team}
                  </MediumText>
                </StyledAutocompleteItem>
              );
            })}
          </StyledAutocompleteContainer>
        )}
      </StyledSearchContainer>
    </StyledSearchWrapper>
  );
};
