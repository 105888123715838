import { SelectOption } from '../components/Select/Select';
import { LEAGUE } from './constants';
import { MLBBoxscore, MLBStat } from './mlb';
import { NBABoxscore, NBAStat } from './nba';
import { NFLBoxscore, NFLStat } from './nfl';
import { NHLBoxscore, NHLStat } from './nhl';

export interface Player {
  name: string;
  player_id: string;
  team: string;
  position: string;
  search_name: string;
  first_name: string;
  last_name: string;
}

export type Boxscore = NFLBoxscore | NBABoxscore | NHLBoxscore | MLBBoxscore;

export interface PlayerStatDisplayData {
  lastGames: number;
  league: LEAGUE;
  player: Player;
  stats: SelectOption[];
}

export interface TeamColor {
  primary: string;
  secondary: string;
  third: string;
}

export type LeagueStat = NFLStat & NBAStat & NHLStat & MLBStat;

export enum OVER_UNDER_CONTEXT {
  OVER = 'over',
  UNDER = 'under',
  NONE = 'none',
}

export type PlayerMap = { [key in LEAGUE]?: Player[] };

export enum ENV {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface AppConfig {
  activeLeagues: LEAGUE[];
  areAdsEnabled: boolean;
  env: ENV;
}

export interface Settings {}
