import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { Search } from '../../components/Search/Search';
import { Select, SelectOption } from '../../components/Select/Select';
import { Button } from '../../components/Button/Button';
import { LAST_GAMES_OPTIONS, LEAGUE, LEAGUE_STAT_OPTIONS_MAP } from '../../models/constants';
import { useEffect, useState } from 'react';
import { Player, PlayerMap } from '../../models/interfaces';
import { generateMenuSnippet } from '../../models/helpers';
import { COLORS, LEAGUE_TEAM_COLOR_MAP } from '../../style/colors';
import { AddIcon } from '../../components/Icon/Icon';

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CONSTANTS.GAP_LARGE};
  position: relative;
`;

const StyledSearchContainer = styled.div`
  z-index: 1;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  z-index: 0;
`;

export interface MenuProps {
  leagueOptions: SelectOption[];
  onAdd: (addition: [Player, LEAGUE, SelectOption[], number]) => void;
  playerMap: PlayerMap;
}

export const Menu = ({ leagueOptions, onAdd, playerMap }: MenuProps) => {
  const [player, setPlayer] = useState<Player | null>(null);
  const [league, setLeague] = useState<SelectOption[]>([leagueOptions[0]]);
  const [stats, setStats] = useState<SelectOption[]>([]);
  const [lastGames, setLastGames] = useState<SelectOption[]>([LAST_GAMES_OPTIONS[0]]);
  const [buttonTitle, setButtonTitle] = useState('');

  const onButtonClick = () => {
    onAdd([player!, league[0].value, stats, lastGames[0].value]);
    setPlayer(null);
  };

  useEffect(() => {
    if (player && league.length > 0 && stats.length > 0 && lastGames.length > 0) {
      const newButtonTitle = generateMenuSnippet(player, stats, lastGames[0].value);
      setButtonTitle(newButtonTitle);
    } else {
      setButtonTitle('');
    }
  }, [player, league, stats, lastGames]);

  useEffect(() => {
    setStats([]);
    setPlayer(null);
  }, [league]);

  return (
    <StyledMenuContainer>
      <StyledSearchContainer>
        <Search
          players={playerMap[league[0].value as LEAGUE]!}
          onSearch={setPlayer}
          placeholder={`Search for ${league[0].label} Players...`}
          value={player}
        />
      </StyledSearchContainer>
      <StyledRowContainer>
        <Select onSelect={setLeague} options={leagueOptions} title="" value={league} />
        <Select multiselect onSelect={setStats} options={LEAGUE_STAT_OPTIONS_MAP[league[0].value as LEAGUE]} title="Stats" value={stats} />
        <Select onSelect={setLastGames} options={LAST_GAMES_OPTIONS} title="" value={lastGames} />
      </StyledRowContainer>
      <Button
        color={league.length > 0 && player ? LEAGUE_TEAM_COLOR_MAP[league[0].value as LEAGUE][player.team].primary : COLORS.BLACK}
        disabled={!player || league.length === 0 || stats.length === 0 || lastGames.length === 0}
        onClick={onButtonClick}
        title={buttonTitle}>
        <AddIcon color={COLORS.WHITE} />
      </Button>
    </StyledMenuContainer>
  );
};
