import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faBaseball,
  faBasketball,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircleExclamation,
  faCircleInfo,
  faFootball,
  faGear,
  faHockeyPuck,
  faMagnifyingGlass,
  faPlus,
  faStar,
  faTag,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { COLORS } from '../../style/colors';
import { LEAGUE } from '../../models/constants';

interface BaseIconProps {
  color?: COLORS;
  icon: IconDefinition;
  id?: string;
  onClick?: () => void;
  size?: SizeProp;
}

export const Icon = ({ color = COLORS.BLACK, icon, id, onClick = () => {}, size = 'lg' }: BaseIconProps) => {
  return <FontAwesomeIcon size={size} icon={icon} id={id} onClick={onClick} style={{ color }} />;
};

export type IconProps = Omit<BaseIconProps, 'icon'>;

export const AddIcon = (props: IconProps) => <Icon {...props} size="xl" icon={faPlus} />;

export const BaseballIcon = (props: IconProps) => <Icon {...props} icon={faBaseball} />;

export const BasketballIcon = (props: IconProps) => <Icon {...props} icon={faBasketball} />;

export const CaretDownIcon = (props: IconProps) => <Icon {...props} size="xl" icon={faCaretDown} />;

export const CaretLeftIcon = (props: IconProps) => <Icon {...props} size="xl" icon={faCaretLeft} />;

export const CaretRightIcon = (props: IconProps) => <Icon {...props} size="xl" icon={faCaretRight} />;

export const CaretUpIcon = (props: IconProps) => <Icon {...props} size="xl" icon={faCaretUp} />;

export const CloseIcon = (props: IconProps) => <Icon {...props} icon={faXmark} />;

export const ErrorIcon = (props: IconProps) => <Icon {...props} color={COLORS.RED} icon={faCircleExclamation} size="2xl" />;

export const FootballIcon = (props: IconProps) => <Icon {...props} icon={faFootball} />;

export const HockeyIcon = (props: IconProps) => <Icon {...props} icon={faHockeyPuck} />;

export const InfoIcon = (props: IconProps) => <Icon {...props} icon={faCircleInfo} />;

export const SearchIcon = (props: IconProps) => <Icon {...props} icon={faMagnifyingGlass} />;

export const SettingsIcon = (props: IconProps) => <Icon {...props} icon={faGear} />;

export const StarIcon = (props: IconProps) => <Icon {...props} icon={faStar} />;

export const TagIcon = (props: IconProps) => <Icon {...props} icon={faTag} />;

interface LeagueIconProps extends IconProps {
  league: LEAGUE;
}

export const LeagueIcon = ({ league, ...props }: LeagueIconProps) => {
  switch (league) {
    case LEAGUE.MLB:
      return <BaseballIcon {...props} />;
    case LEAGUE.NBA:
      return <BasketballIcon {...props} />;
    case LEAGUE.NFL:
      return <FootballIcon {...props} />;
    case LEAGUE.NHL:
      return <HockeyIcon {...props} />;
  }
};
