import { styled } from 'styled-components';

export const Spacer = styled.div`
  flex: 1 0 auto;
`;

export const VerticalListEnd = styled.div<{ $height: number }>`
  min-height: ${({ $height }) => $height}px;
  height: ${({ $height }) => $height}px;
`;

export const HorizontalListEnd = styled.div<{ $width: number }>`
  min-width: ${({ $width }) => $width}px;
  width: ${({ $width }) => $width}px;
`;
