/* eslint-disable no-undef */
import { SelectOption } from '../components/Select/Select';
import { MLB_STAT_OPTIONS } from './mlb';
import { NBA_STAT_OPTIONS } from './nba';
import { NFL_STAT_OPTIONS } from './nfl';
import { NHL_STAT_OPTIONS } from './nhl';

export enum LEAGUE {
  MLB = 'mlb',
  NBA = 'nba',
  NHL = 'nhl',
  NFL = 'nfl',
}

export const SPORTS_OPTIONS: SelectOption[] = [
  {
    label: 'MLB',
    value: LEAGUE.MLB,
  },
  {
    label: 'NBA',
    value: LEAGUE.NBA,
  },
  {
    label: 'NHL',
    value: LEAGUE.NHL,
  },
  {
    label: 'NFL',
    value: LEAGUE.NFL,
  },
];

export const LAST_GAMES_OPTIONS: SelectOption[] = [
  {
    label: 'Last 5',
    value: 5,
  },
  {
    label: 'Last 10',
    value: 10,
  },
  {
    label: 'Last 15',
    value: 15,
  },
];

export const LEAGUE_STAT_OPTIONS_MAP: Record<LEAGUE, SelectOption[]> = {
  [LEAGUE.MLB]: MLB_STAT_OPTIONS,
  [LEAGUE.NBA]: NBA_STAT_OPTIONS,
  [LEAGUE.NHL]: NHL_STAT_OPTIONS,
  [LEAGUE.NFL]: NFL_STAT_OPTIONS,
};
