/* eslint-disable no-undef */
import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { MediumText } from '../Text/Text';
import { AddIcon } from '../Icon/Icon';
import { COLORS } from '../../style/colors';

const StyledButtonContainer = styled.button<{ $color: COLORS | string }>`
  min-height: 40px;
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  box-shadow: ${CONSTANTS.BOX_SHADOW};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${CONSTANTS.GAP_LARGE};
  background-color: ${({ $color }) => $color};
  border: none;
  text-align: left;

  &:disabled {
    background-color: ${COLORS.GRAY};
  }
`;

export interface ButtonProps {
  children?: React.ReactNode;
  color?: COLORS | string;
  disabled?: boolean;
  onClick: () => void;
  title: string;
}

export const Button = ({ children, color = COLORS.BLACK, disabled = false, onClick, title }: ButtonProps) => {
  return (
    <StyledButtonContainer $color={color} disabled={disabled} onClick={onClick}>
      <MediumText color={COLORS.WHITE}>{title}</MediumText>
      {children && children}
    </StyledButtonContainer>
  );
};
