import { LEAGUE } from '../models/constants';
import { TeamColor } from '../models/interfaces';

// https://materialui.co/colors/
export enum COLORS {
  BLACK = '#000000',
  BLUE = '#2196F3',
  GREEN = '#4CAF50',
  ORANGE = '#FF9800',
  PINK = '#E91E63',
  PURPLE = '#673AB7',
  RED = '#F44336',
  YELLOW = '#FBC02D',
  WHITE = '#FFFFFF',
  GRAY = '#BDBDBD',
}

export const MLB_TEAM_COLOR_MAP: { [key: string]: TeamColor } = {
  AZ: { primary: '#A71930', secondary: '#E3D4AD', third: '#30CED8' },
  ATL: { primary: '#CE1141', secondary: '#13274F', third: '#EAAA00' },
  BAL: { primary: '#DF4601', secondary: '#000000', third: '#000000' },
  BOS: { primary: '#BD3039', secondary: '#0C2340', third: '#000000' },
  CHC: { primary: '#0E3386', secondary: '#CC3433', third: '#000000' },
  CWS: { primary: '#27251F', secondary: '#C4CED4', third: '#000000' },
  CIN: { primary: '#C6011F', secondary: '#000000', third: '#000000' },
  CLE: { primary: '#00385D', secondary: '#E50022', third: '#000000' },
  COL: { primary: '#333366', secondary: '#C4CED4', third: '#131413' },
  DET: { primary: '#0C2340', secondary: '#FA4616', third: '#000000' },
  HOU: { primary: '#EB6E1F', secondary: '#002D62', third: '#F4911E' },
  KC: { primary: '#004687', secondary: '#BD9B60', third: '#000000' },
  LAA: { primary: '#BA0021', secondary: '#003263', third: '#862633' },
  LAD: { primary: '#005A9C', secondary: '#EF3E42', third: '#A5ACAF' },
  MIA: { primary: '#00A3E0', secondary: '#EF3340', third: '#41748D' },
  MIL: { primary: '#FFC52F', secondary: '#12284B', third: '#000000' },
  MIN: { primary: '#002B5C', secondary: '#D31145', third: '#B9975B' },
  NYM: { primary: '#002D72', secondary: '#FF5910', third: '#000000' },
  NYY: { primary: '#0C2340', secondary: '#C4CED3', third: '#000000' },
  OAK: { primary: '#003831', secondary: '#EFB21E', third: '#A2AAAD' },
  PHI: { primary: '#E81828', secondary: '#002D72', third: '#000000' },
  PIT: { primary: '#FDB827', secondary: '#27251F', third: '#000000' },
  SD: { primary: '#2F241D', secondary: '#FFC425', third: '#000000' },
  SF: { primary: '#FD5A1E', secondary: '#27251F', third: '#EFD19F' },
  SEA: { primary: '#005C5C', secondary: '#0C2C56', third: '#C4CED4' },
  STL: { primary: '#C41E3A', secondary: '#0C2340', third: '#FEDB00' },
  TB: { primary: '#092C5C', secondary: '#8FBCE6', third: '#F5D130' },
  TEX: { primary: '#003278', secondary: '#C0111F', third: '#000000' },
  TOR: { primary: '#134A8E', secondary: '#1D2D5C', third: '#E8291C' },
  WSH: { primary: '#AB0003', secondary: '#14225A', third: '#000000' },
};

export const NFL_TEAM_COLOR_MAP: { [key: string]: TeamColor } = {
  ARI: { primary: '#97233F', secondary: '#FFB612', third: '#000000' },
  ATL: { primary: '#A71930', secondary: '#000000', third: '#A5ACAF' },
  BAL: { primary: '#241773', secondary: '#000000', third: '#9E7C0C' },
  BUF: { primary: '#00338D', secondary: '#C60C30', third: '#000000' },
  CAR: { primary: '#0085CA', secondary: '#101820', third: '#BFC0BF' },
  CHI: { primary: '#0B162A', secondary: '#C83803', third: '#000000' },
  CIN: { primary: '#FB4F14', secondary: '#000000', third: '#999999' },
  CLE: { primary: '#311D00', secondary: '#FF3C00', third: '#000000' },
  DAL: { primary: '#041E42', secondary: '#7F9695', third: '#000000' },
  DEN: { primary: '#FB4F14', secondary: '#002244', third: '#000000' },
  DET: { primary: '#0076B6', secondary: '#B0B7BC', third: '#000000' },
  GB: { primary: '#203731', secondary: '#FFB612', third: '#000000' },
  HOU: { primary: '#03202F', secondary: '#A71930', third: '#000000' },
  IND: { primary: '#002C5F', secondary: '#A2AAAD', third: '#000000' },
  JAX: { primary: '#006778', secondary: '#9F792C', third: '#D7A22A' },
  KC: { primary: '#E31837', secondary: '#FFB81C', third: '#000000' },
  LV: { primary: '#000000', secondary: '#A5ACAF', third: '#999999' },
  OAK: { primary: '#000000', secondary: '#A5ACAF', third: '#999999' },
  LAC: { primary: '#0080C6', secondary: '#FFC20E', third: '#000000' },
  LAR: { primary: '#003594', secondary: '#FFD100', third: '#FFA300' },
  LA: { primary: '#003594', secondary: '#FFD100', third: '#FFA300' }, // Rams edge case
  MIA: { primary: '#008E97', secondary: '#FC4C02', third: '#005778' },
  MIN: { primary: '#4F2683', secondary: '#FFC62F', third: '#000000' },
  NE: { primary: '#002244', secondary: '#C60C30', third: '#B0B7BC' },
  NO: { primary: '#D3BC8D', secondary: '#101820', third: '#000000' },
  NYG: { primary: '#0B2265', secondary: '#A71930', third: '#A5ACAF' },
  NYJ: { primary: '#125740', secondary: '#000000', third: '#999999' },
  PHI: { primary: '#004C54', secondary: '#A5ACAF', third: '#000000' },
  PIT: { primary: '#101820', secondary: '#FFB612', third: '#A5ACAF' },
  SF: { primary: '#AA0000', secondary: '#B3995D', third: '#000000' },
  SEA: { primary: '#002244', secondary: '#69BE28', third: '#A5ACAF' },
  TB: { primary: '#D50A0A', secondary: '#FF7900', third: '#34302B' },
  TEN: { primary: '#0C2340', secondary: '#4B92DB', third: '#C8102E' },
  WAS: { primary: '#5A1414', secondary: '#FFB612', third: '#000000' },
};

export const NBA_TEAM_COLOR_MAP: { [key: string]: TeamColor } = {
  ATL: { primary: '#C8102E', secondary: '#FDB927', third: '#000000' },
  BOS: { primary: '#007A33', secondary: '#BA9653', third: '#000000' },
  BKN: { primary: '#000000', secondary: '#FFFFFF', third: '#000000' },
  CHA: { primary: '#1D1160', secondary: '#00788C', third: '#A1A1A4' },
  CHI: { primary: '#CE1141', secondary: '#000000', third: '#000000' },
  CLE: { primary: '#860038', secondary: '#041E42', third: '#FDBB30' },
  DAL: { primary: '#00538C', secondary: '#002B5E', third: '#000000' },
  DEN: { primary: '#0E2240', secondary: '#FEC524', third: '#8B2131' },
  DET: { primary: '#C8102E', secondary: '#1D42BA', third: '#BEC0C2' },
  GSW: { primary: '#1D428A', secondary: '#FFC72C', third: '#000000' },
  HOU: { primary: '#CE1141', secondary: '#000000', third: '#000000' },
  IND: { primary: '#002D62', secondary: '#FDBB30', third: '#BEC0C2' },
  LAC: { primary: '#C8102E', secondary: '#1D428A', third: '#BEC0C2' },
  LAL: { primary: '#552583', secondary: '#FDB927', third: '#000000' },
  MEM: { primary: '#5D76A9', secondary: '#12173F', third: '#F5B112' },
  MIA: { primary: '#98002E', secondary: '#F9A01B', third: '#000000' },
  MIL: { primary: '#00471B', secondary: '#EEE1C6', third: '#0077C0' },
  MIN: { primary: '#0C2340', secondary: '#236192', third: '#78BE20' },
  NOP: { primary: '#0C2340', secondary: '#C8102E', third: '#85714D' },
  NYK: { primary: '#006BB6', secondary: '#F58426', third: '#BEC0C2' },
  OKC: { primary: '#007AC1', secondary: '#EF3B24', third: '#002D62' },
  ORL: { primary: '#0077C0', secondary: '#C4CED4', third: '#000000' },
  PHI: { primary: '#006BB6', secondary: '#ED174C', third: '#002B5C' },
  PHX: { primary: '#E56020', secondary: '#1D1160', third: '#000000' },
  POR: { primary: '#E03A3E', secondary: '#000000', third: '#000000' },
  SAC: { primary: '#5A2D81', secondary: '#63727A', third: '#000000' },
  SAS: { primary: '#C4CED4', secondary: '#000000', third: '#000000' },
  TOR: { primary: '#CE1141', secondary: '#000000', third: '#A1A1A4' },
  UTA: { primary: '#002B5C', secondary: '#00471B', third: '#F9A01B' },
  WAS: { primary: '#002B5C', secondary: '#E31837', third: '#C4CED4' },
};

export const NHL_TEAM_COLOR_MAP: { [key: string]: TeamColor } = {
  ANA: { primary: '#F47A38', secondary: '#B9975B', third: '#C1C6C8' },
  ARI: { primary: '#8C2633', secondary: '#E2D6B5', third: '#111111' },
  BOS: { primary: '#FFB81C', secondary: '#000000', third: '#000000' },
  BUF: { primary: '#003087', secondary: '#FFB81C', third: '#FFFFFF' },
  CGY: { primary: '#D2001C', secondary: '#FAAF19', third: '#FFFFFF' },
  CAR: { primary: '#CE1126', secondary: '#A4A9AD', third: '#000000' },
  CHI: { primary: '#CF0A2C', secondary: '#D18A00', third: '#000000' },
  COL: { primary: '#6F263D', secondary: '#236192', third: '#A2AAAD' },
  CBJ: { primary: '#002654', secondary: '#CE1126', third: '#A4A9AD' },
  DAL: { primary: '#006847', secondary: '#8F8F8C', third: '#111111' },
  DET: { primary: '#CE1126', secondary: '#000000', third: '#FFFFFF' },
  EDM: { primary: '#FF4C00', secondary: '#041E42', third: '#000000' },
  FLA: { primary: '#041E42', secondary: '#C8102E', third: '#B9975B' },
  LAK: { primary: '#111111', secondary: '#A2AAAD', third: '#FFFFFF' },
  MIN: { primary: '#A6192E', secondary: '#154734', third: '#EAAA00' },
  MTL: { primary: '#AF1E2D', secondary: '#192168', third: '#000000' },
  NSH: { primary: '#FFB81C', secondary: '#041E42', third: '#FFFFFF' },
  NJD: { primary: '#CE1126', secondary: '#000000', third: '#FFFFFF' },
  NYI: { primary: '#00539B', secondary: '#F47D30', third: '#FFFFFF' },
  NYR: { primary: '#0038A8', secondary: '#CE1126', third: '#FFFFFF' },
  OTT: { primary: '#000000', secondary: '#DA1A32', third: '#B79257' },
  PHI: { primary: '#F74902', secondary: '#000000', third: '#FFFFFF' },
  PIT: { primary: '#000000', secondary: '#FCB514', third: '#FFFFFF' },
  STL: { primary: '#002F87', secondary: '#FCB514', third: '#041E42' },
  SJS: { primary: '#006D75', secondary: '#EA7200', third: '#000000' },
  SEA: { primary: '#001628', secondary: '#99D9D9', third: '#E9072B' },
  TBL: { primary: '#002868', secondary: '#FFFFFF', third: '#000000' },
  TOR: { primary: '#00205B', secondary: '#FFFFFF', third: '#000000' },
  VAN: { primary: '#00205B', secondary: '#00843D', third: '#041C2C' },
  VGK: { primary: '#B4975A', secondary: '#333F42', third: '#C8102E' },
  WSH: { primary: '#041E42', secondary: '#C8102E', third: '#FFFFFF' },
  WPG: { primary: '#041E42', secondary: '#004C97', third: '#AC162C' },
};

export const LEAGUE_TEAM_COLOR_MAP: Record<LEAGUE, { [key: string]: TeamColor }> = {
  [LEAGUE.MLB]: MLB_TEAM_COLOR_MAP,
  [LEAGUE.NFL]: NFL_TEAM_COLOR_MAP,
  [LEAGUE.NBA]: NBA_TEAM_COLOR_MAP,
  [LEAGUE.NHL]: NHL_TEAM_COLOR_MAP,
};
