import { SelectOption } from '../components/Select/Select';

export interface NHLBoxscore {
  opponent: string;
  gameday: string;
  points: number;
  goals: number;
  assists: number;
  shots: number;
  is_away: boolean;
}

export type NHLStat = keyof Omit<NHLBoxscore, 'opponent' | 'gameday' | 'is_away'>;

export const NHL_STAT_OPTIONS: SelectOption[] = [
  {
    label: 'Assists',
    value: 'assists',
  },
  {
    label: 'Goals',
    value: 'goals',
  },
  {
    label: 'Points',
    value: 'points',
  },
  {
    label: 'Shots',
    value: 'shots',
  },
];
