import { styled } from 'styled-components';
import { Base } from '../Base/Base';
import { Menu } from '../../views/Menu/Menu';
import { StatDisplay } from '../../views/StatDisplay/StatDisplay';
import { LEAGUE } from '../../models/constants';
import { SelectOption } from '../../components/Select/Select';
import { useContext, useEffect, useState } from 'react';
import { Player, PlayerStatDisplayData } from '../../models/interfaces';
import { CONSTANTS, DESKTOP } from '../../style/constants';
import { AppContext } from '../../contexts/AppContext';
import { Tabs } from '../../components/Tabs/Tabs';
import { MenuSkeleton } from '../../views/MenuSkeleton/MenuSkeleton';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useErrorBoundary } from 'react-error-boundary';

const StyledHomeContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: left;
  padding: 2px 10px 0 10px;
  overflow-y: scroll;
`;

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CONSTANTS.GAP_LARGE};

  ${DESKTOP.SMALL} {
    margin: 0 auto;
    width: 580px;
  }
`;

export const calculateLayout = (length: number, max: number): string => {
  if (length < max) {
    const width = 580 * length + 10 * (length - 1);
    return `
      display: grid;
      width: ${width}px;
      margin-left: auto;
      margin-right: auto;
      margin-botom: 10px;
    `;
  } else {
    return `
      display: grid;
      grid-template-columns: repeat(${max}, 1fr);
    `;
  }
};

const StyledHomeWrapper = styled.div<{ $isMobile: boolean; $length: number }>`
  gap: ${CONSTANTS.GAP_LARGE};
  display: flex;
  flex-direction: column;

  ${DESKTOP.SMALL} {
    ${({ $length }) => calculateLayout($length, 2)}
  }

  ${DESKTOP.MEDIUM} {
    ${({ $length }) => calculateLayout($length, 3)}
  }

  ${DESKTOP.LARGE} {
    ${({ $length }) => calculateLayout($length, 4)}
  }

  margin: ${({ $isMobile }) => ($isMobile ? '0 0 150px 0' : '0 0 10px 0')};
  padding: 0 0 -10px 0;
`;

export const Home = () => {
  const { hasError, isLoading, leagueOptions, playerMap } = useContext(AppContext);
  const isMobile = useIsMobile();
  const [statsDisplays, setStatDisplays] = useState<PlayerStatDisplayData[]>([]);
  const [leagueFilter, setLeagueFilter] = useState<SelectOption | null>(null);
  const { showBoundary } = useErrorBoundary();

  const onAdd = ([player, league, stats, lastGames]: [Player, LEAGUE, SelectOption[], number]) => {
    const newStatDisplay: PlayerStatDisplayData = {
      lastGames,
      league,
      player,
      stats,
    };
    setStatDisplays([newStatDisplay, ...statsDisplays]);
  };

  const onDelete = (id: string) => {
    const newStatDisplays = statsDisplays.filter(display => display.player.player_id !== id);
    setStatDisplays([...newStatDisplays]);
  };

  useEffect(() => {
    if (hasError) {
      showBoundary(hasError);
    }
  }, [hasError]);

  return (
    <Base>
      <StyledHomeContainer>
        <StyledMenuContainer>
          {isLoading && <MenuSkeleton />}
          {!isLoading && (
            <>
              <Menu leagueOptions={leagueOptions!} onAdd={onAdd} playerMap={playerMap!} />
              <Tabs tabs={leagueOptions!} onTab={setLeagueFilter} value={leagueFilter} />
            </>
          )}
        </StyledMenuContainer>
        <StyledHomeWrapper
          $isMobile={isMobile}
          $length={statsDisplays.filter(display => leagueFilter === null || leagueFilter?.value === display.league).length}>
          {statsDisplays.map(display => (
            <StatDisplay
              data={display}
              hidden={leagueFilter !== null && leagueFilter?.value !== display.league}
              key={display.player.name}
              onDelete={onDelete}
            />
          ))}
        </StyledHomeWrapper>
      </StyledHomeContainer>
    </Base>
  );
};
