import { SelectOption } from '../components/Select/Select';

export interface MLBBoxscore {
  gameday: string;
  is_away: boolean;
  opponent: string;
  runs: number;
  singles: number;
  doubles: number;
  triples: number;
  home_runs: number;
  strike_outs: number;
  walks: number;
  hits: number;
  stolen_bases: number;
  rbi: number;
  total_bases: number;
  p_strikeouts: number;
  p_walks: number;
  p_hits: number;
  p_home_runs: number;
  p_total_bases: number;
  p_earned_runs: number;
  p_innings: number;
}

export type MLBStat = keyof Omit<MLBBoxscore, 'opponent' | 'gameday' | 'is_away'>;

export const MLB_STAT_OPTIONS: SelectOption[] = [
  {
    label: 'Runs',
    value: 'runs',
  },
  {
    label: 'Singles',
    value: 'singles',
  },
  {
    label: 'Doubles',
    value: 'doubles',
  },
  {
    label: 'Triples',
    value: 'triples',
  },
  {
    label: 'Home Runs',
    value: 'home_runs',
  },
  {
    label: 'Strikeouts',
    value: 'strike_outs',
  },
  {
    label: 'Walks',
    value: 'walks',
  },
  {
    label: 'Hits',
    value: 'hits',
  },
  {
    label: 'Stolen Bases',
    value: 'stolen_bases',
  },
  {
    label: 'RBIs',
    value: 'rbi',
  },
  {
    label: 'Total Bases',
    value: 'total_bases',
  },
  {
    label: 'Strikeouts (Pitching)',
    value: 'p_strikeouts',
  },
  {
    label: 'Walks (Pitching)',
    value: 'p_walks',
  },
  {
    label: 'Hits (Pitching)',
    value: 'p_hits',
  },
  {
    label: 'Home Runs (Pitching)',
    value: 'p_home_runs',
  },
  {
    label: 'Total Bases (Pitching)',
    value: 'p_total_bases',
  },
  {
    label: 'Earned Runs',
    value: 'p_earned_runs',
  },
  {
    label: 'Innings Pitched',
    value: 'p_innings',
  },
];
