import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { MediumText, SmallText } from '../Text/Text';
import { COLORS, LEAGUE_TEAM_COLOR_MAP } from '../../style/colors';
import { LEAGUE } from '../../models/constants';
import { OverUnderInputState } from '../OverUnderInput/OverUnderInput';
import { OVER_UNDER_CONTEXT } from '../../models/interfaces';
import { formatGameday } from '../../models/helpers';

const StyledStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  scroll-snap-align: start;
`;

const StyledDateContainer = styled.div`
  min-height: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
`;

const StyledStatCirlcle = styled.div<{ $color: COLORS }>`
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTeamLabel = styled.div<{ $color: string }>`
  min-height: 20px;
  height: 20px;
  width: 40px;
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $color }) => $color};
`;

export interface StatProps {
  gameday: string;
  isAway: boolean;
  league: LEAGUE;
  opponent: string;
  overUnderInputState: OverUnderInputState;
  value: number;
}

export const Stat = ({ gameday, isAway, league, opponent, overUnderInputState, value }: StatProps) => {
  const getStatColor = (state: OverUnderInputState, value: number): COLORS => {
    if (state.context === OVER_UNDER_CONTEXT.OVER) {
      return state.value && value >= state.value ? COLORS.GREEN : COLORS.BLACK;
    } else if (state.context === OVER_UNDER_CONTEXT.UNDER) {
      return state.value && value < state.value ? COLORS.RED : COLORS.BLACK;
    } else {
      return COLORS.BLACK;
    }
  };

  return (
    <StyledStatContainer>
      <StyledDateContainer>
        <SmallText>{formatGameday(gameday.slice(5))}</SmallText>
      </StyledDateContainer>
      <StyledStatCirlcle $color={getStatColor(overUnderInputState, value)}>
        <MediumText color={COLORS.WHITE}>{value}</MediumText>
      </StyledStatCirlcle>
      <StyledTeamLabel $color={LEAGUE_TEAM_COLOR_MAP[league][opponent].primary}>
        <SmallText color={COLORS.WHITE}>
          {isAway ? '@' : ''}
          {opponent}
        </SmallText>
      </StyledTeamLabel>
    </StyledStatContainer>
  );
};
