import React, { createContext, useEffect, useState } from 'react';
import { SPORTS_OPTIONS } from '../models/constants';
import { AppConfig, Player, PlayerMap } from '../models/interfaces';
import { getAppConfig, getPlayers } from '../models/api';
import { SelectOption } from '../components/Select/Select';

export interface AppContextProps {
  appConfig: AppConfig | null;
  hasError: boolean;
  isLoading: boolean;
  leagueOptions: SelectOption[] | null;
  playerMap: PlayerMap | null;
}

export const AppContext = createContext<AppContextProps>({
  appConfig: null,
  hasError: false,
  isLoading: true,
  leagueOptions: null,
  playerMap: null,
});

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leagueOptions, setLeagueOptions] = useState<SelectOption[] | null>(null);
  const [playerMap, setPlayerMap] = useState<PlayerMap | null>(null);

  useEffect(() => {
    // Get the App Configuration
    getAppConfig()
      .then((config: AppConfig | null) => {
        if (config) {
          // Set the App Configuration
          setAppConfig(config);
          // Get the players from the active leagues
          const { activeLeagues } = config;
          const playerPromises = activeLeagues.map(league => getPlayers(league));
          Promise.all(playerPromises)
            .then(playerArrays => {
              const playerMap: PlayerMap = playerArrays.reduce((map: PlayerMap, players: Player[] | null, index: number) => {
                if (players) {
                  map[activeLeagues[index]] = players;
                }
                return map;
              }, {});
              // Set the Player Map
              setPlayerMap(playerMap);
            })
            .catch(_ => setHasError(true));
        }
      })
      .catch(_ => setHasError(true));
  }, []);

  useEffect(() => {
    if (appConfig && playerMap) {
      setIsLoading(false);
    }
  }, [appConfig, playerMap]);

  useEffect(() => {
    if (appConfig) {
      const { activeLeagues } = appConfig;
      setLeagueOptions(SPORTS_OPTIONS.filter(({ value }: SelectOption) => activeLeagues.includes(value)));
    }
  }, [appConfig]);

  const value = {
    appConfig,
    leagueOptions,
    playerMap,
    isLoading,
    hasError,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
