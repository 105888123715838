import { SelectOption } from '../components/Select/Select';

export interface NFLBoxscore {
  is_away: boolean;
  opponent: string;
  season: string;
  week: number;
  completions: number;
  passing_yards: number;
  passing_tds: number;
  interceptions: number;
  rushing_yards: number;
  rushing_tds: number;
  receptions: number;
  receiving_yards: number;
  receiving_tds: number;
  fantasy_points: number;
  fantasy_points_ppr: number;
  gameday: string;
}

export type NFLStat = keyof Omit<NFLBoxscore, 'gameday' | 'opponent' | 'season' | 'week' | 'is_away'>;

export const NFL_STAT_OPTIONS: SelectOption[] = [
  {
    label: 'Completions',
    value: 'completions',
  },
  {
    label: 'Standard Fantasy Points',
    value: 'fantasy_points',
  },
  {
    label: 'PPR Fantasy Points',
    value: 'fantasy_points_ppr',
  },
  {
    label: 'Interceptions',
    value: 'interceptions',
  },
  {
    label: 'Passing TDs',
    value: 'passing_tds',
  },
  {
    label: 'Passing Yards',
    value: 'passing_yards',
  },
  {
    label: 'Receiving Yards',
    value: 'receiving_yards',
  },
  {
    label: 'Receiving TDs',
    value: 'receiving_tds',
  },
  {
    label: 'Receptions',
    value: 'receptions',
  },
  {
    label: 'Rushing Yards',
    value: 'rushing_yards',
  },
  {
    label: 'Rushing TDs',
    value: 'rushing_tds',
  },
];
