import { styled } from 'styled-components';
import { COLORS } from '../../style/colors';
import { CaretDownIcon, CaretUpIcon } from '../Icon/Icon';
import { FONTS } from '../../style/fonts';
import { OVER_UNDER_CONTEXT } from '../../models/interfaces';
import { useEffect, useRef } from 'react';

const StyledOverUnderInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const StyledInputContainer = styled.div<{ $color: COLORS }>`
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: solid 2px ${({ $color }) => $color};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledInput = styled.input`
  height: 22px;
  width: 36px;
  border: none;
  text-align: center;
  ${FONTS.MEDIUM}

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${COLORS.GRAY};
  }
`;

const StyledButtonContainer = styled.div`
  min-height: 20px;
  height: 20px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface OverUnderInputState {
  context: OVER_UNDER_CONTEXT;
  value?: number;
}

export interface OverUnderInputProps {
  onOverUnderInputChange: (state: OverUnderInputState) => void;
  state: OverUnderInputState;
}

export const parseInput = (value: string): number | undefined => {
  return value ? Number(value) : undefined;
};

export const OverUnderInput = ({ onOverUnderInputChange, state }: OverUnderInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = `${state.value ?? ''}`;
    }
  }, [state]);

  const onClick = (value: OVER_UNDER_CONTEXT) => {
    const { context } = state;
    if (context === value) {
      onOverUnderInputChange({ ...state, context: OVER_UNDER_CONTEXT.NONE });
    } else {
      onOverUnderInputChange({ ...state, context: value });
    }
  };

  return (
    <StyledOverUnderInputContainer>
      <StyledButtonContainer onClick={() => onClick(OVER_UNDER_CONTEXT.OVER)}>
        <CaretUpIcon color={state.context === OVER_UNDER_CONTEXT.OVER ? COLORS.GREEN : COLORS.GRAY} />
      </StyledButtonContainer>
      <StyledInputContainer
        $color={state.context === OVER_UNDER_CONTEXT.OVER ? COLORS.GREEN : state.context === OVER_UNDER_CONTEXT.UNDER ? COLORS.RED : COLORS.GRAY}>
        <StyledInput
          placeholder="#"
          ref={inputRef}
          type="number"
          onChange={event => onOverUnderInputChange({ ...state, value: parseInput(event.target.value) })}
        />
      </StyledInputContainer>
      <StyledButtonContainer onClick={() => onClick(OVER_UNDER_CONTEXT.UNDER)}>
        <CaretDownIcon color={state.context === OVER_UNDER_CONTEXT.UNDER ? COLORS.RED : COLORS.GRAY} />
      </StyledButtonContainer>
    </StyledOverUnderInputContainer>
  );
};
