import { styled } from 'styled-components';
import { InfoIcon } from '../Icon/Icon';
import { Heading1 } from '../Text/Text';
import { useContext } from 'react';
import { MODAL, ModalContext } from '../../contexts/ModalContext';

const StyledHeaderContainer = styled.div`
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .placeholder {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const Header = () => {
  const { setModal } = useContext(ModalContext);

  return (
    <StyledHeaderContainer>
      <div onClick={() => setModal(MODAL.INFO)}>
        <InfoIcon />
      </div>
      <Heading1>LAST GAMES</Heading1>
      {/* <div onClick={() => setModal(MODAL.SETTINGS)}>
        <SettingsIcon />
      </div> */}
      <div className="placeholder"></div>
    </StyledHeaderContainer>
  );
};
