import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { COLORS, LEAGUE_TEAM_COLOR_MAP } from '../../style/colors';
import { CloseIcon, LeagueIcon } from '../../components/Icon/Icon';
import { MediumText, SmallText } from '../../components/Text/Text';
import { Stat } from '../../components/Stat/Stat';
import { StatHeader } from '../../components/StatHeader/StatHeader';
import { OverUnderInput, OverUnderInputState } from '../../components/OverUnderInput/OverUnderInput';
import { Boxscore, LeagueStat, OVER_UNDER_CONTEXT, PlayerStatDisplayData } from '../../models/interfaces';
import { useEffect, useState } from 'react';
import { LoadingIcon } from '../../components/LoadingIcon/LoadingIcon';
import { HorizontalListEnd, Spacer } from '../../components/Util/Util';
import { generateMenuSnippet, getAverageFromBoxscore, getOverUnderCountFromBoxscore } from '../../models/helpers';
import { getPlayerStats } from '../../models/api';
import { useIsMobile } from '../../hooks/useIsMobile';
import { SelectOption } from '../../components/Select/Select';

const StyledStatDisplayContainer = styled.div<{ $hidden: boolean }>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  flex-direction: column;
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  box-shadow: ${CONSTANTS.BOX_SHADOW};
  overflow: hidden;
`;

const StyledHeader = styled.div<{ $color: string; $isOpen: boolean }>`
  height: 40px;
  padding: 0 ${CONSTANTS.PADDING_LARGE};
  margin: 0 0 ${({ $isOpen }) => ($isOpen ? '6px' : '0')} 0;
  background-color: ${({ $color }) => $color};
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  align-items: center;
`;

const StyledStatContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  padding: 0 0 0 ${CONSTANTS.PADDING_LARGE};
  margin: 4px 0 0 0;
`;

const StyledStatList = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
`;

const StyledSummaryRow = styled.div`
  padding: ${CONSTANTS.PADDING_LARGE};
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CONSTANTS.GAP_LARGE};
  align-items: center;
  justify-content: center;
  padding: 15px ${CONSTANTS.PADDING_LARGE} 20px ${CONSTANTS.PADDING_LARGE};
  text-align: center;
`;

export interface StatDisplayProps {
  data: PlayerStatDisplayData;
  hidden: boolean;
  onDelete: (id: string) => void;
}

export const StatDisplay = ({ data, hidden, onDelete }: StatDisplayProps) => {
  const { lastGames, league, player, stats } = data;
  const [boxscores, setBoxscores] = useState<Boxscore[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(true);
  const [statIndex, setStatIndex] = useState(0);
  const [overUnderInputStateMap, setOverUnderInputStateMap] = useState<{ [key: string]: OverUnderInputState }>({});

  const onNext = () => {
    const nextIndex = statIndex + 1;
    if (nextIndex >= stats.length) {
      setStatIndex(0);
    } else {
      setStatIndex(nextIndex);
    }
  };

  const onOverUnderInputChange = (state: OverUnderInputState, label: string) => {
    setOverUnderInputStateMap({
      ...overUnderInputStateMap,
      [label]: state,
    });
  };

  const generateSummary = (): string => {
    const { context, value } = overUnderInputStateMap[stats[statIndex].label];
    const stat = stats[statIndex];
    const average = getAverageFromBoxscore(boxscores!, stat.value as LeagueStat);
    const overUnderCount = getOverUnderCountFromBoxscore(boxscores!, stat.value as LeagueStat, { context, value });
    const gamesCount = boxscores!.length;

    if (value === undefined || context === OVER_UNDER_CONTEXT.NONE) {
      return `${player.name} is averaging ${average.toFixed(2)} ${stat.label.toLowerCase()} in his last ${gamesCount} games.`;
    } else {
      return `${
        player.name
      } is ${context} ${value} ${stat.label.toLowerCase()} in ${overUnderCount} of his last ${gamesCount} games, with an average of ${average.toFixed(
        2
      )} ${stat.label.toLowerCase()} per game.`;
    }
  };

  useEffect(() => {
    getPlayerStats(player.player_id, league, lastGames).then(boxscores => setBoxscores(boxscores));
  }, []);

  useEffect(() => {
    if (boxscores) {
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [boxscores]);

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true);
    }
  }, [isMobile]);

  useEffect(() => {
    const overUnderInputStateMap = stats.reduce(
      (acc: { [key: string]: OverUnderInputState }, { label }: SelectOption) => ({
        ...acc,
        [label]: {
          context: OVER_UNDER_CONTEXT.NONE,
        },
      }),
      {}
    );
    setOverUnderInputStateMap(overUnderInputStateMap);
  }, [stats]);

  return (
    <StyledStatDisplayContainer $hidden={hidden}>
      <StyledHeader
        $color={LEAGUE_TEAM_COLOR_MAP[league][player.team]?.primary ?? COLORS.BLACK}
        $isOpen={isOpen}
        onClick={() => isMobile && !isLoading && setIsOpen(!isOpen)}>
        <LeagueIcon league={league} color={COLORS.WHITE} />
        <MediumText color={COLORS.WHITE}>
          {player.name} - {player.team}
        </MediumText>
        <Spacer />
        <div onClick={() => onDelete(player.player_id)}>
          <CloseIcon color={COLORS.WHITE} />
        </div>
      </StyledHeader>
      {isLoading && (
        <StyledLoadingContainer>
          <LoadingIcon league={league} />
          <MediumText>Loading {generateMenuSnippet(player, stats, lastGames)}</MediumText>
        </StyledLoadingContainer>
      )}
      {isOpen && !isLoading && (
        <>
          <StatHeader isPaginated={stats.length > 1} title={stats[statIndex].label} onNext={onNext} />
          <StyledStatContainer>
            <OverUnderInput
              state={overUnderInputStateMap[stats[statIndex].label]}
              onOverUnderInputChange={(state: OverUnderInputState) => onOverUnderInputChange(state, stats[statIndex].label)}
            />
            <StyledStatList>
              {boxscores!.map(boxscore => (
                <Stat
                  key={boxscore.gameday}
                  gameday={boxscore.gameday}
                  isAway={boxscore.is_away}
                  league={league}
                  opponent={boxscore.opponent}
                  overUnderInputState={overUnderInputStateMap[stats[statIndex].label]}
                  value={boxscore[stats[statIndex].value as LeagueStat]}
                />
              ))}
              <HorizontalListEnd $width={1} />
            </StyledStatList>
          </StyledStatContainer>
          <StyledSummaryRow>
            <SmallText>{generateSummary()}</SmallText>
          </StyledSummaryRow>
        </>
      )}
    </StyledStatDisplayContainer>
  );
};
