import { styled } from 'styled-components';
import { CONSTANTS } from '../../style/constants';
import { range } from 'lodash';

const StyledMenuSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CONSTANTS.GAP_LARGE};
  position: relative;
`;

const StyledSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONSTANTS.GAP_LARGE};
  flex: 1;

  > div {
    flex: 1;
  }
`;

const StyledSkeleton = styled.div`
  height: 40px;
  border-radius: ${CONSTANTS.BORDER_RADIUS};
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(0, 0%, 100%);
    }
    100% {
      background-color: hsl(0, 0%, 100%);
      box-shadow: ${CONSTANTS.BOX_SHADOW};
    }
  }
`;

export const MenuSkeleton = () => (
  <StyledMenuSkeletonContainer>
    <StyledSkeleton />
    <StyledSkeletonContainer>
      {range(0, 3).map(key => (
        <StyledSkeleton key={key} />
      ))}
    </StyledSkeletonContainer>
    <StyledSkeleton />
    <StyledSkeletonContainer>
      {range(0, 3).map(key => (
        <StyledSkeleton key={key} />
      ))}
    </StyledSkeletonContainer>
  </StyledMenuSkeletonContainer>
);
